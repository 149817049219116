import "styles/pages/page-gallery.scss";

import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import ZoomWrapper from "components/ZoomWrapper";
import Breadcrumps from "components/Breadcrumps";

const PageGallery = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pageGallery;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const images = pageData.gallery?.map((item) => item.galleryImage?.sourceUrl);
  const parsedImages = parseGalleryImages(images);

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.data.title} />
      <SubpageHeader title={pageContext.data.title} />
      <section className="gallery-info">
        <div className="container">
          <h2 className="section-title">{pageData.title}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div
            className="gallery-info__text"
            dangerouslySetInnerHTML={{ __html: pageData.description }}
          />
        </div>
      </section>
      <section className="gallery">
        <div className="container">
          <div className="row g-0">
            {parsedImages.map((images, index) => {
              const isGrouped = Array.isArray(images);
              const groupCount = parsedImages
                .slice(0, index)
                .filter((images) => Array.isArray(images)).length;

              return (
                <div
                  key={index}
                  className={`col-md-${
                    isGrouped
                      ? "2"
                      : (index + groupCount) % 10 === 4
                      ? "2"
                      : (index + groupCount) % 10 === 8 ||
                        (index + groupCount) % 10 === 9
                      ? "6"
                      : "4"
                  }`}
                >
                  {isGrouped ? (
                    images.map((image, imageIndex) => (
                      <div
                        key={imageIndex}
                        className="gallery__img gallery__img--small"
                        style={{ backgroundImage: `url(${image})` }}
                      >
                        <ZoomWrapper
                          onClick={(e) => {
                            e.preventDefault();
                            setIsLightboxOpen(true);
                            setPhotoIndex(index + imageIndex + groupCount);
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className="gallery__img gallery__img--big"
                      style={{ backgroundImage: `url(${images})` }}
                    >
                      <ZoomWrapper
                        onClick={(e) => {
                          e.preventDefault();
                          setIsLightboxOpen(true);
                          setPhotoIndex(index + groupCount);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Layout>
  );
};

const parseGalleryImages = (images, step = 10) => {
  if (!images?.length > 0) return undefined;

  const result = [];

  for (let i = 0; i < images.length; i++) {
    if ((i + 1) % step === 2) {
      const group = [];
      if (images[i]) group.push(images[i]);
      if (images[i + 1]) group.push(images[i + 1]);
      result.push(group);
      i++;
    } else {
      result.push(images[i]);
    }
  }

  return result;
};

export default PageGallery;
